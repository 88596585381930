import Vue from 'vue';
import VueRouter from 'vue-router';
import Idron from '../views/Idron.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Idron,
  },
  {
    path: '/Idron',
    name: 'Idron',
    component: Idron,
  },
  {
    path: '/Gourette',
    name: 'Gourette',
    component: () => import('../views/Gourette.vue'),
  },
  {
    path: '/Bareges',
    name: 'Bareges',
    component: () => import('../views/Bareges.vue'),
  },
  {
    path: '/Arrens',
    name: 'Arrens',
    component: () => import('../views/Arrens.vue'),
  },
  {
    path: '/Hautacam',
    name: 'Hautacam',
    component: () => import('../views/Hautacam.vue'),
  },
  {
    path: '/Comparaison',
    name: 'Comparaison',
    component: () => import('../views/Comparaison.vue'),
  },
  {
    path: '/BRAs',
    name: 'BRAs',
    component: () => import('../views/BRAs.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: { auth: 'admin' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: { auth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/403',
    name: '403',
    component: () => import('../views/Error/Error403.vue'),
  },
];

const router = new VueRouter({
  routes,
});

Vue.router = router;

export default router;
