<template>
  <div class="text-center">
    <p class="title">Meteoblue - Multimodel</p>
    <b-tabs content-class="mt-3">
    <b-tab title="3j" active class="small-padding">
      <base-64-image :place="place" type="meteoblue-multimodel-3j"></base-64-image>
    </b-tab>
    <b-tab title="7j" class="small-padding">
      <base-64-image :place="place" type="meteoblue-multimodel-7j"></base-64-image>
    </b-tab>
  </b-tabs>

  </div>
</template>

<script>
import Base64Image from '@/components/Base64Image.vue';

export default {
  name: 'MeteoblueMultimodel',
  components: {
    Base64Image,
  },
  props: {
    place: String,
  },
};
</script>
