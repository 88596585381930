<template>
  <div class="text-center">
    <p class="title">Meteociel - Arome</p>
    <b-tabs content-class="mt-3">
    <b-tab title="3h" active>
      <base-64-image :place="place" type="meteociel-arome"></base-64-image>
    </b-tab>
    <b-tab title="1h">
      <base-64-image :place="place" type="meteociel-arome-1h"></base-64-image>
    </b-tab>
  </b-tabs>

  </div>
</template>

<script>
import Base64Image from '@/components/Base64Image.vue';

export default {
  name: 'MeteocielArome',
  components: {
    Base64Image,
  },
  props: {
    place: String,
  },
};
</script>
