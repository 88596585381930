<template>
  <b-overlay :show="showOverlay">
    <img v-if="!error" class="img-fluid min-height" :src="base64"/>
    <p v-if="error">{{error}}</p>
  </b-overlay>
</template>

<script>
export default {
  name: 'Base64Image',
  props: {
    place: String,
    type: String,
  },
  data() {
    return {
      base64: '',
      showOverlay: true,
      error: null,
    };
  },
  created() {
    this.getResource();
  },
  methods: {
    async getResource() {
      this.error = null;
      this.showOverlay = true;
      try {
        const response = await this.axios.get(`/resource/${this.place}/${this.type}`);
        if (response && response.data) {
          this.base64 = `data:image/png;base64,${response.data.base64}`;
        }
      } catch (error) {
        if (error && error.data && error.data.error) {
          this.error = error.data.error;
        }
      }
      this.showOverlay = false;
    },
  },

};
</script>

<style scoped>
  .min-height {
    min-height: 50px;
  }
</style>
